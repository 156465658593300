/**
 *
 */
export const config = {
  API_URL : "http://google.fr",
  /**
   * URL of the open id server.
   *
   * @type {string}
   */
  OPEN_ID_SERVER_URL: 'https://keycloak.dev.tup.transcity/realms/denmark_bo_operators',

  /**
   * Open id client id.
   *
   * @type {string}
   */
  OPEN_ID_CLIENT_ID: 'bowp',
}

// merge with env
Object.assign(config, window["config"] || {})
