<div *ngIf="alert === 'OK'" class="alert alert-success alert-pgs" role="alert">
  {{alertMessage}}
</div>
<div *ngIf="alert === 'KO'" class="alert alert-danger alert-pgs" role="alert">
  {{alertMessage}}
</div>
<div class="body col-md-12">

  <div class="card card-container">

    <form name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
      <div class="form-group">
        <h3>Username</h3>
        <input
          type="text"
          class="form-control"
          name="username"
          [(ngModel)]="form.username"
          required
          #username="ngModel"
        />
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="username.errors && f.submitted"
        >
          Username is required!
        </div>
      </div>
      <div class="form-group">
        <h3>Password</h3>
        <input
          type="password"
          class="form-control"
          name="password"
          [(ngModel)]="form.password"
          required
          minlength="3"
          #password="ngModel"
        />
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="password.errors && f.submitted"
        >
          <div *ngIf="password.errors.required">Password is required</div>
          <div *ngIf="password.errors.minlength">
            Password must be at least 3 characters
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-block">
          Login
        </button>
      </div>
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && isLoginFailed"
        >
          Login failed: {{ errorMessage }}
        </div>
      </div>
    </form>
    <button (click)="loginOAuth2()" [disabled]="!configured()" class="btn btn-primary btn-block">
      USE TRANSCITY SSO
    </button>


  </div>
</div>
