import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "./core/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'ifsng-webapp-pgs';
  showNav = true;

  constructor(private router: Router, private authService: AuthService, private route: Router) {

    if (window.location.href.includes("state")) {
      authService.userManager.signinRedirectCallback().then((user) => {
        // User is authenticated
        authService.saveOAuth2Session(user);
        if (authService.isLogged()) {
          router.navigate(['/home']);
        }
      });
    }

    this.router.events.subscribe(event => {
      if (window.location.href.includes("state")) {
        //
      }else if (event['routerEvent']) {

        if (event['routerEvent'].url.startsWith("/webTokenizer")) {
          this.showNav = false;
        } else if (event['routerEvent'].url.startsWith("/login")) {

          if (authService.isLogged()) {
            router.navigate(['/home']);
          }
          this.showNav = false;
        } else {
          if (!window.location.href.includes("state")) {
            if (!authService.isLogged()) {
              router.navigate(['/login']);

            }
            this.showNav = true;
          }
        }
      }
    });
  }

  logout() {
    this.authService.logout();
  }

}
