import { Component, OnInit } from '@angular/core';
import { AuthService } from "../core/auth.service";
import {config} from "../app.config";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  alertMessage = "";
  alert = "";

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    if (this.authService.getToken()) {
      this.isLoggedIn = true;
    }
  }

  failAlert(message) {
    this.alert = "KO";
    this.alertMessage = message;
    setTimeout(() => {
      this.clearAlert();
    }, 2000);
  }


  clearAlert() {
    this.alert = "";
  }

  loginOAuth2(){
    this.authService.loginOAuth2();
  }

  configured(){
    return config.OPEN_ID_SERVER_URL && config.OPEN_ID_CLIENT_ID;
  }


  onSubmit(): void {
    const {username, password} = this.form;

    if (this.authService.isLogged()) {
      console.log("Refresh token ... ");
      this.authService.refreshToken();
    }else {
      console.log("login ...");
      this.authService.login(username, password).subscribe(
        data => {
          this.authService.saveSession(data);
          this.reloadPage();
         },
        err => {
          this.errorMessage = err.error.message;
          this.failAlert("Wrong Username/password !");
        }
      );
    }
  }

  reloadPage(): void {
    window.location.reload();
  }
}
